export enum PageRows {
	firstOption = 10,
	secondOption = 15,
	thirdOption = 20,
	fourthOption = 25,
	fifthOption = 50,
	sixthOption = 100,
}

export enum Numbers {
	minusTwo = -2,
	zero = 0,
	zeroAndHalf = 0.5,
	zeroPointSixFive = 0.65,
	zeroPointEight = 0.8,
	zeroPointOne = 0.1,
	one = 1,
	two = 2,
	three = 3,
	four = 4,
	fourAndHalf = 4.5,
	five = 5,
	eight = 8,
	ten = 10,
	fifteen = 15,
	sixteen = 16,
	twenty = 20,
	twentyThree = 23,
	twentyFour = 24,
	twentyFive = 25,
	twentyEight = 28,
	thirty = 30,
	thritySix = 36,
	fourtyEight = 48,
	fifty = 50,
	fiftyNine = 59,
	sixty = 60,
	sixtyFive = 65,
	hundred = 100,
	twoFifty = 250,
	fourHundredFifty = 450,
	fiveHundred = 500,
	sixFifty = 650,
	nineNintyNine = 999,
	thousand = 1000,
	twoThousand = 2000,
	TIME_ONE_DAY_IN_MILLI_SECS = 86400000,
	TIME_FOURTY_FIVE_IN_MILLI_SECS = 2700000,
	oneLakh = 100000,
	sixtyThousand = 60000,
	zeroxff = 0xff,
}

export enum QUE_JOURNEY_STEPS {
	STEP_BASIC_DETAILS = 1,
	STEP_AUDIENCE = 2,
	STEP_QUESTIONS = 3,
	STEP_PREVIEW = 4,
	STEP_NOTIFICATIONS = 5,
}

export enum COMMON {
	RANDOM_MULTIPLE = 1000000,
	DEFAULT_CHAR_LIMIT = 62000,
}

export enum DURATIONS {
	THIRTY = 30,
	SIXTY = 60,
	NINETY = 90,
}
